<template>
    <div>
        <v-dialog v-model="$store.state.dialog" width="800">
            <v-card flat class="card">
                <v-toolbar outlined flat color="grey lighten-4">
                    <v-toolbar-title>
                        الرد على
                        <span v-if="$store.state.itemDetails.type == 1" class="error--text">شكوى</span>
                        <span v-if="$store.state.itemDetails.type == 2" class="green--text">اقتراح</span>
                        من الزبون 
                        <span class="grey--text"> {{$store.state.itemDetails.formUserFullName}} </span>
                    </v-toolbar-title>
                    <v-spacer />
                    <v-btn @click="close()" icon>
                        <v-icon>close</v-icon>
                    </v-btn>
                </v-toolbar>

                <v-form @submit.prevent="submit()" ref="form" lazy-validation v-model="valid">
                    <v-container>
                        <v-card outlined>
                            <v-container>
                                <v-row>
                                    <v-col cols="12" md="12">
                                        <h4 class="mb-2"> اختر من الردود الجاهزة<span class="required">*</span></h4>
                                        <v-autocomplete
                                            v-model="replay"
                                            @input="item.replay = replay"
                                            :items="$global.state.complaintSolutions"
                                            :loading="$global.state.loading"
                                            clearable
                                            item-text="problem"
                                            item-value="solution"
                                            color="info"
                                            placeholder="اختر من الردود"
                                            no-data-text="لا توجد بيانات"
                                            hide-details
                                            prepend-inner-icon="mdi-reply"
                                            outlined
                                            filled
                                        />
                                    </v-col>

                                    <v-col cols="12" md="12">
                                        <h4 class="mb-2"> الرد </h4>
                                        <v-textarea
                                            v-model="item.replay"
                                            placeholder="مطبعة شمس الغري"
                                            color="info"
                                            :rules="[$global.state.required()]"
                                            required
                                            filled
                                            outlined
                                            hide-details="auto"
                                            rows="3"
                                        ></v-textarea>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card>
                        <!-- actions -->
                        <v-col cols="12" md="12">
                            <v-row class="mt-2">
                                <v-btn
                                    type="submit"
                                    :loading="$global.state.loading"
                                    x-large
                                    color="primary"
                                    class="secondary--text btn"
                                    :disabled="!valid"
                                >إرسال الرد</v-btn>
                                <v-btn
                                    @click="close()"
                                    x-large
                                    color="error"
                                    class="mr-2 btn"
                                    outlined
                                >إلغاء</v-btn>
                            </v-row>
                        </v-col>
                    </v-container>
                </v-form>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            valid: false,
            replay: "",
            item: {},
        };
    },

    mounted() {
        this.reset();
        this.$eventBus.$on("fill-fields", () => {
            this.item = this.$store.state.itemDetails;
        });
        this.getComplaintSolutions();
    },

    methods: {
        reset() {
            this.item = {
                replay: "",
            };
            this.replay = "";
        },

        submit() {
            this.$global.state.loading = true;
            this.$http
                .put( `${this.$route.meta.endPoint}/reply/${this.item.id}`, this.item)
                .then((res) => {
                    this.close();
                    this.$store.dispatch("toastification/setToast", { message: `تم إرسال الرد  بنجاح `, type: "success",});
                })
                .catch((err) => {
                    this.$store.dispatch("toastification/setToast", {
                        message: `${err.data.message}`,
                        type: "error",
                    });
                })
                .finally(() => (this.$global.state.loading = false));
        },

        getComplaintSolutions() {
            this.$global.dispatch(`getComplaintSolutions`);
        },

        close() {
            this.$store.commit("setDialog");
            this.reset();
            this.resetValidation();
            this.$store.commit("setItemDetails", {});
        },

        resetValidation() {
            this.$refs.form.resetValidation();
        },
    },
};
</script>



